import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
// import { Button, FormControl, InputGroup } from 'react-bootstrap'

import * as style from './SectionCta.module.scss'

const SectionCta = (props) => {
  const { className, ...rest } = props

  return (
    <section {...rest} className={classNames(style.sectionCta, className)}>
      <h2 data-label="FREE $BLOCK">
        <span>FREE $BLOCK</span>
      </h2>
      <p className={classNames(style.textDescription, 'text-description')}>
        ENTER YOUR DETAILS BELOW TO CLAIM <br />
        YOUR <span className="text-color-gradient-primary">FREE $10</span> BLOCK
        TOKEN
      </p>
      {/* <InputGroup
        className={classNames('input-group--gradient-border', style.inpEmail)}
      >
        <FormControl placeholder="Email" type="email" />
        <div className="form-control--gradient-border" />
      </InputGroup>
      <Button variant="neon" className={style.inpButton}>
        PRE-REGISTER NOW
      </Button> */}
      <iframe
        title="SIB"
        width={320}
        height={600}
        src="https://dbab0812.sibforms.com/serve/MUIEAD0CzBzdEyFAKMzVxeFfPFekmGNcgnujTcsORFClndrPHvj8v1loo9nvBgMMaoiu1lkRZYcv_VKyBKLT2iP7e22W9EGW6dIZqLRgxOcqgr8IR2LAUWkO08HAJgGy3yUgLbeSA1pXRGsc_26GyQKrIKXAIuVvoBr-glQtmDx6MBEHEXxWp5rx3Qx75gnfn3NxiidT8pw16-A-"
        frameBorder={0}
        scrolling="no"
        allowFullScreen=""
        style={{
          marginTop: -40,
          // marginLeft: -10,
        }}
      />
    </section>
  )
}

SectionCta.defaultProps = {
  className: '',
}

SectionCta.propTypes = {
  className: PropTypes.string,
}

export default SectionCta
