// extracted by mini-css-extract-plugin
export var why = "gj_wj";
export var why_title = "gj_xj";
export var why_descr = "gj_yj";
export var why_media = "gj_zj";
export var why_video = "gj_Bj";
export var why_frame = "gj_Cj";
export var why_player = "gj_Dj";
export var why_card = "gj_Fj";
export var why_pic = "gj_Gj";
export var why_wrap = "gj_Hj";
export var active = "gj_Jj";
export var why_cards = "gj_Kj";
export var why_icon = "gj_Lj";