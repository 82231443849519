import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Slider from 'react-slick'

import 'slick-carousel/slick/slick.css'

import { REVIEWS } from '~components/SectionReviews/mocks'

import CardReview from './components/CardReview'

import * as style from './SectionReviews.module.scss'

const settings = {
  dots: false,
  infinite: true,
  fade: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 5000,
  pauseOnFocus: false,
  pauseOnHover: true,
  adaptiveHeight: true,
}

const SectionReviews = (props) => {
  const { className, ...rest } = props

  return (
    <section {...rest} className={classNames(style.sectionReviews, className)}>
      <div className={style.sectionContent}>
        <h3 className={style.textHeadingTop}>WHAT THE COMMUNITY ARE SAYING</h3>
        <div className={style.sliderContainer}>
          <Slider {...settings}>
            {REVIEWS.map(({ id, ...reviewProps }) => (
              <div key={id} className={style.cardWrapper}>
                <CardReview {...reviewProps} />
              </div>
            ))}
          </Slider>
        </div>
        <h3 className={style.textHeadingBottom}>
          DON&apos;T MISS YOUR SHOT ON THE{' '}
          <span className="text-color-gradient-primary">NEXT RISING STAR</span>
        </h3>
      </div>
    </section>
  )
}

SectionReviews.defaultProps = {
  className: '',
}

SectionReviews.propTypes = {
  className: PropTypes.string,
}

export default SectionReviews
