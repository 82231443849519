import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import * as s from './Hero.module.scss'

const Hero = ({ pic }) => {
  return (
    <section className={s.hero}>
      <GatsbyImage
        className={s.hero_pic}
        image={pic.childImageSharp.gatsbyImageData}
        alt={pic.name}
      />
    </section>
  )
}

export default Hero
