import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import Icon from '~components/Icon'

import * as style from './CardReview.module.scss'

const CardReview = (props) => {
  const { className, imgUrl, username, tweetUrl, reviewText, ...rest } = props

  return (
    <div {...rest} className={classNames(style.cardReview, className)}>
      <img
        src={imgUrl}
        alt={username}
        width={64}
        height={64}
        className={style.img}
      />
      <span className={style.textUsername}>{username}</span>
      <a
        href={tweetUrl}
        target="_blank"
        rel="nofollow noopener noreferrer"
        title="See on Twitter"
        className={style.linkTweet}
      >
        <Icon name="discord" size={24} />
      </a>
      <p className={style.textReview}>{reviewText}</p>
    </div>
  )
}

CardReview.defaultProps = {
  className: '',
  imgUrl: undefined,
  username: undefined,
  tweetUrl: undefined,
  reviewText: undefined,
}

CardReview.propTypes = {
  className: PropTypes.string,
  imgUrl: PropTypes.string,
  username: PropTypes.string,
  tweetUrl: PropTypes.string,
  reviewText: PropTypes.string,
}

export default CardReview
