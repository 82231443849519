import React, { useState } from 'react'
import ReactPlayer from 'react-player'
import { Container } from 'react-bootstrap'
import { GatsbyImage } from 'gatsby-plugin-image'
import Icon from '~components/Icon'
import cn from 'classnames'
import * as s from './Why.module.scss'

const Why = ({ title, descr, video, preview, cards }) => {
  const youtubeConfig = {
    playerVars: {
      autoplay: 1,
      // This parameter lets you use a YouTube player that does not show a YouTube logo
      modestbranding: 1,
      // This parameter controls whether videos play inline or fullscreen on iOS
      playsinline: 0,
      // This parameter indicates whether the player should show related videos
      // when playback of the initial video ends
      rel: 0,
    },
  }

  const [active, setActive] = useState(false)

  return (
    <section className={s.why}>
      <Container>
        <h2 className={s.why_title} data-label={title}>
          <span>{title}</span>
        </h2>
        <p className={s.why_descr}>{descr}</p>
      </Container>
      <div className={s.why_media}>
        <Container className={cn(s.why_wrap, { [s.active]: active })}>
          <div className={s.why_video}>
            <div className={s.why_frame}>
              <ReactPlayer
                className={s.why_player}
                config={{ youtube: youtubeConfig }}
                width={null}
                height={null}
                url={video}
                light={preview.publicURL || true}
                playIcon={
                  <div className={s.why_icon}>
                    <Icon name="play" size={26} />
                  </div>
                }
                controls
                onPlay={() => setActive(true)}
                onPause={() => setActive(false)}
                onEnded={() => setActive(false)}
              />
            </div>
          </div>
          <ul className={s.why_cards}>
            {cards.map((item) => {
              return (
                <li key={item.name} className={s.why_card}>
                  <div className={s.why_pic}>
                    <GatsbyImage
                      image={item.childImageSharp.gatsbyImageData}
                      alt={item.name}
                    />
                  </div>
                </li>
              )
            })}
          </ul>
        </Container>
      </div>
    </section>
  )
}

export default Why
