/* eslint-disable react/prop-types */
import React from 'react'
import { graphql } from 'gatsby'
import Layout from '~components/Layout'
import S from '~components/seo'
import Hero from '~components/Hero'
import Why from '~components/Why'
import SectionCta from '~components/SectionCta'
import SectionReviews from '~components/SectionReviews'

const IndexPage = ({ data }) => {
  const { hero, why } = data.contentJson
  return (
    <Layout>
      <S />
      <Hero {...hero} />
      <Why {...why} />
      <SectionCta />
      <SectionReviews />
    </Layout>
  )
}

export const query = graphql`
  {
    contentJson {
      hero {
        pic {
          name
          childImageSharp {
            gatsbyImageData(quality: 100, width: 1093, placeholder: NONE)
          }
        }
      }
      why {
        title
        descr
        cards {
          name
          childImageSharp {
            gatsbyImageData(quality: 100, width: 176)
          }
        }
        video
        preview {
          publicURL
        }
      }
    }
  }
`

export default IndexPage
