import profileAvatar1 from './mocks/profile-avatar-1.png'
import profileAvatar2 from './mocks/profile-avatar-2.png'

// eslint-disable-next-line import/prefer-default-export
export const REVIEWS = [
  {
    id: 1,
    imgUrl: profileAvatar1,
    username: 'SKINHEADWAZZA',
    tweetUrl: 'https://discord.com/invite/blockasset',
    reviewText:
      'That’s the whole aim of Blockasset to get the fans and athletes together and that’s crazyyy',
  },
  {
    id: 2,
    imgUrl: profileAvatar2,
    username: 'MARKTHESHARK',
    tweetUrl: 'https://discord.com/invite/blockasset',
    reviewText:
      'Social tokens for athletes in any individual sport like MMA is going to be huge! amazing way to take fandom to the next level!',
  },
]
