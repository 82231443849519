import React from 'react'
import { Container } from 'react-bootstrap'
import PropTypes from 'prop-types'
import logo from '~img/logo.png'
import Social from '~components/Social'
import * as s from './style.module.scss'

const Footer = ({ siteTitle }) => {
  const currentYear = new Date().getFullYear()

  return (
    <footer className={s.footer}>
      <Container className={s.footer_inner}>
        <div>
          <img width="174" height="42" src={logo} alt={siteTitle} />
        </div>
        <Social />
        <div className={s.footer_copy}>
          © {siteTitle} {currentYear}. ALL RIGHTS RESERVED
        </div>
      </Container>
    </footer>
  )
}

Footer.defaultProps = {
  siteTitle: '',
}

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

export default Footer
