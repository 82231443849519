import React from 'react'
import Icon from '~components/Icon'
import DATA from './constants'
import * as s from './style.module.scss'

const Social = () => {
  return (
    <ul className={s.social}>
      {DATA.map(({ name, link }) => (
        <li key={name}>
          <a
            className={s.social_link}
            href={link}
            target="_blank"
            rel="noreferrer"
          >
            <Icon name={name} size={24} />
          </a>
        </li>
      ))}
    </ul>
  )
}

export default Social
